<template>
  <m-field
    :value="controller.value"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :loading="loading"
    :success="success"
    :color="color"
    :dark="dark"
    :light="light"
    class="m-select"
    :class="{ vertical }"
  >
    <v-item-group
      v-model="controller.value"
      active-class="active primary--text"
      :mandatory="mandatory"
      :multiple="multiple"
      :color="color"
      :class="{ 'flex-column justify-start': vertical }"
      class="m-select-controller d-flex"
      @change="change"
    >
      <v-item 
        v-for="(option, o) in controller.options"
        :key="`m-select-option-${o}`"
        v-slot="{ active, toggle }"
        :value="option.value"
        class="m-select-option flex-grow-1 flex-shrink-1"
        :class="{ 'disabled': disabled||option.disabled }"
      >
        <v-card
          flat
          tile
          min-height="52"
          :class="{ 'flex-column justify-center': !vertical, 'pa-6': large }"
          class="option-content d-flex align-center"
          @click="toggle"
        >
          <v-icon
            v-if="checkbox||option.icon!==undefined"
            :color="active ? color : undefined"
            :large="large"
            :class="{ 'mb-4': large&&vertical, 'mr-4': large&&!vertical }"
            class="ml-4 mr-2"
          >
            {{ checkbox ? active ? icons.checked : icons.unchecked : option.icon }}
          </v-icon>
          <span 
            :class="[{ 'px-4': vertical }, 'text-'+(vertical ? 'body-1' : 'overline')]"
            class="text text-truncate d-inline-block"
          >{{ option.text }}</span>
          <span 
            v-if="hasCaption(option)"
            class="text-caption my-2"
          >
            {{ option.caption }}
          </span>
        </v-card>
      </v-item>
    </v-item-group>
  </m-field>
</template>

<style lang="scss">

.m-select {
}

.m-select, .m-select-option {
  position: relative;
  // overflow: hidden;
}

// .m-select .m-select-controller {
//   width: 100%;
// }

.m-select .m-select-option {
  width: 100%;
  min-width: 144px;
  flex-basis: 0 !important;
}
.m-select .m-select-option.disabled {
  opacity: .4;
  pointer-events: none;
}

.m-select:not(.vertical) .option-content:not(:last-child) {
  border-right: 1px solid var(--v-grey-darken2);
}

.m-select .active .text {
  font-weight: 700 !important;
}


</style>

<script>
  import {
    mdiCheckboxBlankOutline, 
    mdiCheckboxMarked,
  } from '@mdi/js'

  export default {
    
    data: () => ({
      icons: {
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline
      },
      controller: {
        value: null,
        options: []
      }
    }),

    watch: {
      value: {
        immediate: true,
        deep: true,
        handler (value) {
          this.controller.value = value;
        }
      },
      options: {
        immediate: true,
        deep: true,
        handler (options) {
          this.controller.options = _.map(options, option => {
            option = { disabled: false, ...(_.isObject(option) ? option : { text: option, value: option }) }
            return option;
          });
        }
      }
    },

    methods: {
      change (selected) {
        console.log('select option', this.controller.value);
        this.$emit('change', this.controller.value);
      },

      hasCaption (option) {
        return option.hasOwnProperty('caption');
      }

    },

    props: {
      value: {
        type: [String, Number, Array],
        default: 0
      },
      options: {
        type: [Array, Object],
        default: () => []
      },
      label: {
        type: String,
        default: undefined
      },
      color: {
        type: String,
        default: undefined
      },
      hint: {
        type: String,
        default: undefined
      },
      rules: {
        type: Array,
        default: () => []
      },
      dark: {
        type: Boolean,
        default: undefined
      },
      light: {
        type: Boolean,
        default: undefined
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      checkbox: {
        type: Boolean,
        default: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      success: {
        type: Boolean,
        default: undefined
      },
      vertical: {
        type: Boolean,
        default: false
      },
      large: {
        type: Boolean,
        default: false
      }
    },

    components: {
      MField: () => import('@/components/mField'),
    },
  }
</script>